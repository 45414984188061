<template>
  <v-card class="my-auto px-6 py-4">
    <v-row>
      <v-col>
        <CalendarOrderSummary
          :buy_items="buy_items"
          :start_date="start_date"
          :allow_point="false"
          v-on:paymentChange="onPaymentChange"
          ref="order_summary"
        >
          <template v-slot:summary>
            <v-data-table
              :headers="headers"
              :options.sync="options"
              :items="buy_items"
              hide-default-footer
              disable-pagination
            ></v-data-table>
          </template>
        </CalendarOrderSummary>
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        :ripple="false"
        :elevation="0"
        class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default
          py-5
          px-6
          mt-6
          mb-2
          me-2
          ms-auto
        "
        color="primary"
        @click="createRechargeShopList()"
      >
        {{ $t("Payment") }}
      </v-btn>
    </v-row>
    <v-row v-if="false">
      <v-col md="6" class="mt-16 mx-auto text-center">
        <h2 class="text-typo text-h2 font-weight-bolder mb-2">
          Frequently Asked Questions
        </h2>
        <p class="text-body">
          A lot of people don't appreciate the moment until it’s passed. I'm not
          trying my hardest, and I'm not trying to do
        </p>
      </v-col>

      <v-col md="10" class="mx-auto">
        <v-expansion-panels flat color="#ddd">
          <v-expansion-panel
            v-for="panel in panels"
            :key="panel.title"
            class="bg-transparent"
          >
            <v-expansion-panel-header>
              <h5 class="text-h5 font-weight-bold text-typo">
                {{ panel.title }}
              </h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="text-body text-sm opacity-8">{{ panel.description }}</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  point_type_enum,
  payment_enum,
  shop_type_enum,
} from "@/definitions.js";
import CalendarOrderSummary from "./Widgets/CalendarOrderSummary.vue";
import CommonUtility from "@/util/CommonUtility.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";

export default {
  name: "recharge-summary",
  props: {
    buy_items: {
      type: [Array],
      default: () => [],
    },
    start_date: {
      type: String,
      default: null,
    },
  },
  mixins: [HttpCommonMixin],
  data(vm) {
    return {
      point_type: point_type_enum,
      payment_enum: payment_enum,
      shop_type_enum: shop_type_enum,
      options: {},
      headers: [
        {
          text: vm.$i18n.t("name"),
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("price"),
          value: "price",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("point"),
          value: "point",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: vm.$i18n.t("bonus"),
          value: "bonus",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
      ],
      develop_pay: false,
    };
  },
  components: {
    CalendarOrderSummary,
  },
  methods: {
    ...mapGetters(["getToken"]),
    onPaymentChange() {},
    getPoints() {
      let points = 0;
      this.buy_items.forEach((item) => {
        points += parseInt(item.point);
      });
      return points;
    },
    createRechargeShopList() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/shops/`;
      let items = [];
      let credit = 0;
      this.buy_items.forEach((item) => {
        credit += parseInt(item.price);
        items.push({
          price: item.price,
          stock: item.price,
          point: item.point,
          bonus: item.bonus,
          quantity: 1,
          name: item.name,
          key_num: 0,
          owner_num: 0,
          creditor_num: 0,
        });
      });

      const data = {
        total: this.$refs.order_summary.total,
        pay: this.payment_enum.CreditorCard,
        tax: this.$refs.order_summary.tax,
        name: this.buy_items[0].name,
        credit: credit,
        tax_include: this.$refs.order_summary.tax_include,
        start_date: CommonUtility.getNowDate(),
        end_date: this.$refs.order_summary.end_date,
        items: items,
        shop_type: shop_type_enum.Points,
      };

      this.doHttpPost(url, data, this.onShopListCreated);
    },
    onShopListCreated(data) {
      if (this.develop_pay == true) {
        this.displaySummary(data);
      } else {
        this.createPay(data.order_id);
      }
    },
    displaySummary(data) {
      alert("訂單更新完成 信用卡付帳");
      const order_id = data.order_id;
      this.$router.push({
        path: "/pages/customer/order-done/",
        query: { order_id },
      });
    },
    createPay(order_id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/ccard/?order_id=${order_id}`;
      window.location.href = url;
    },
    pay() {
      // Test code for admin
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/points/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      const data = {
        type: this.point_type.Point,
        amount: this.getPoints(),
      };
      console.log("para", data);
      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setResult(data) {
      alert("call the add api");
      console.log(data);
    },
  },
};
</script>
